<div class="container error-container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="error-img mt-4">
        <img src="assets/img/error-img.png" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <div class="error-msg mt-4">
        <h3>
          We seem to have run into a bit of a problem with this page,<span>
            kindly reload.</span
          >
        </h3>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-md-12">
      <div class="error-button">
        <a href="" class="btn">Go to Home Page</a>
      </div>
    </div>
  </div>
</div>
